.admin-timesheet-item {
  
  .btn-clipboard {
    padding: 2px 5px;
    font-size: 16px;
  }

  .btn-header {
    padding: 2px 5px;
    font-size: 16px;
  }

  .spinner-with-data {
    position: absolute;
    top: 10px;
    right: 12px;
  }
}

.create-timesheet {
  .order {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    line-height: 30px;
    margin-right: 5px;
    background-color: var(--info);
    color: white;
    font-size: 16px;
    text-align: center;
  }
}
