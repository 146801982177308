.super-badge {
  z-index: 1;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .project {
    border-radius: 10px 0 0 10px;
    padding: 4px;
    padding-left: 6px;
    color: white;
  }
  .task {
    background-color: white;
    border-radius: 0 10px 10px 0;
    border: solid lightgrey;
    border-width: 1px 1px 1px 0;
    padding: 3px 6px 3px 4px;
  }
}
