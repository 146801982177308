.virtual-office-table {
  .who-row {
    > * {
      vertical-align: middle;
    }

    > span {
      margin-right: 5px;
    }
  }

  .where-row {
    line-height: normal;

    .flag {
      height: 14px;
      width: 20px;
      margin-right: 5px;
    }

    > * {
      vertical-align: middle;
    }

    > span {
      margin-right: 5px;
      text-transform: capitalize;
    }
  }

  .phone {
    padding: 6px 8px;

    span {
      vertical-align: middle;

      &:first-child {
        margin-right: 3px;
      }
    }
  }

  .spinner-with-data {
    position: absolute;
    top: 10px;
    right: 12px;
  }
}

.working-indicator {
  .green-indicator,
  .yellow-indicator,
  .blue-indicator,
  .green-indicator,
  .red-indicator,
  .grey-indicator {
    display: inline-block;
    vertical-align: middle;
    width: 12px;
    height: 12px;
    margin-right: 5px;
    border-radius: 12px;
  }

  .green-indicator {
    background: #62c762;
    animation: blinker 4s cubic-bezier(0.5, 0, 1, 1) infinite;
  }

  .yellow-indicator {
    background: #f1c40f;
    animation: blinker 6s cubic-bezier(0.5, 0, 1, 1) infinite;
  }

  .blue-indicator {
    background: #00a8ff;
    animation: blinker 6s cubic-bezier(0.5, 0, 1, 1) infinite;
  }

  .grey-indicator {
    background: #E3E4E8;
  }

  .red-indicator {
    background: #EB5B25;
    animation: blinker 3s cubic-bezier(0.5, 0, 1, 1) infinite;
  }
}

.red-indicator {
  display: inline-block;
  vertical-align: middle;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  border-radius: 12px;
  background: #ec4561;
  animation: blinker 2s cubic-bezier(0.5, 0, 1, 1) infinite;
}

@keyframes blinker {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
