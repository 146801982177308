.global-filter {
  position: relative;

  input {
    padding-right: 23px;
  }

  button {
    position: absolute;
    right: 0;
    top: 0;
    height: 35px;
    width: 28px;
    padding: 0;
    border: 0;
    background: none;
    color: black;
    opacity: 0.6;

    &:focus,
    &:hover {
      opacity: 0.9;
    }
  }
}
