.team {
  .spinner-with-data {
    position: absolute;
    top: 10px;
    right: 12px;
  }

  .directory-card {
    padding: 15px;

    .photo-bg {
      background-size: 70px 70px;
      background-repeat: no-repeat;
      background-position: center;
    }

    .photo {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    .contents {
      flex: 1;
      padding: 0 10px;
    }
  }
}

.photo-file-input {
  position: absolute;
  clip: rect(0px, 0px, 0px, 0px);
}

.switch-label {
  vertical-align: middle;
  margin-right: 8px;
  height: 28px;
}

.photo-container {
  height: 300px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 auto;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  img {
    opacity: 0.6;
  }
}

.logo-container {
  height: 100px;
  width: auto;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 auto;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  img {
    opacity: 0.6;
  }
}

.logo-placeholder {
  height: 100px;
  opacity: 0.5;
  width: auto;
  padding: 5px;
}
