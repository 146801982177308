@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.timetracker {
  position: relative;

  .spinner-with-data {
    position: absolute;
    top: 10px;
    right: 12px;
  }

  .notes-wrapper {
    margin-top: 15px;
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 300ms ease-in-out;

    &.open {
      opacity: 1;
      height: 127px;
    }
  }

  .notes-container {
    display: flex;
    flex-direction: column;
  }

  .notes {
    width: 100%;
    height: 100px;
    resize: none;
    border: none;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 5px 7px;
  }

  .notes-button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.timer {
  .clock {
    width: 58px;
    height: 58px;
    background-color: #ffffff;
    box-shadow: inset 0px 0px 0px 4px #333;
    border-radius: 50%;
    position: relative;
    margin: auto;

    &:after,
    &:before {
      position: absolute;
      content: '';
      transform-origin: 2px 2px;
      background-color: #333;
      height: 4px;
      top: 26px;
      left: 26px;
    }

    &:before {
      width: 20px;
    }

    &:after {
      width: 16px;
    }

    &.animated {
      &:before {
        animation: spin 2000ms linear infinite;
      }

      &:after {
        animation: spin 8000ms linear infinite;
      }
    }
  }

  .time {
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .mini-label {
    padding: 0 4px;
    -webkit-clip-path: polygon(100% 0%, 95% 50%, 100% 100%, 0% 100%, 0% 0%, 0% 0%);
    clip-path: polygon(100% 0%, 95% 50%, 100% 100%, 0% 100%, 0% 0%, 0% 0%);
  }
}

.task-tooltip {
  z-index: 1000;
  max-width: 400px;
}
.progress-bar-animated {
  position: relative;

  .progress-bar::after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;

    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    animation: shimmer;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
}

@keyframes shimmer {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
  }
}
