.timesheet-item {
  transition: box-shadow 350ms ease-in-out;
  
  > .card-header {
    background-color: #333547;
    color: #ffffff;

    h6 {
      margin: 0;
      flex: 1;
    }
  }

  &.card {
    border: 1px solid rgba(0, 0, 0, 0.125);
  }

  &.selected {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  }

  .request-payment-row {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
