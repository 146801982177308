@import 'variables';

.top-bar-overlay {
  position: relative;
  margin-left: 240px;

  .enlarged & {
    margin-left: 70px;
  }

  @media screen and (max-width: 768px) {
    margin-left: 0 !important;
  }

  .top-bar-overlay-wrapper {
    height: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    padding: $top-bar-overlay-vertical-padding 30px;

    transition: height 350ms ease-in-out, opacity 350ms;
    overflow: hidden;
    width: 100%;

    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    z-index: -1;

    &.bar-visible {
      height: $top-bar-overlay-height;
      opacity: 1;
      pointer-events: all;
    }

    @media screen and (max-width: 768px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.audioWrapper {
  display: table;
  width: 50%;
  height: 50%; 
  border: 0px solid #c0c0c0; 
}

.audioHolder {
  text-align: center;
  display: table-cell; 
  vertical-align: middle;
}
