
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500|Sarabun:400,600,700');

// Color variables
$white: #ffffff;

$primary: #626ed4;
$success: #02a499;
$info: #38a4f8;
$warning: #f8b425;
$danger: #ec4561;
$dark: #2a3142;
$muted: #9ca8b3;


// Gray Colors
$gray-100:                  #f8f9fa;
$gray-200:                  #e9ecef;
$gray-300:                  #dee2e6;
$gray-400:                  #ced4da;
$gray-500:                  #adb5bd;
$gray-600:                  #6c757d;
$gray-700:                  #495057;
$gray-800:                  #343a40;
$gray-900:                  #212529;

$bg-body: #f8f8fa;
$left-bar: #333547;
$topbar-background: #ececf1;
$topbar-search-background: #e0e0ea;
$footer-background: #f1f2f5;

$font-family: 'Roboto', sans-serif;
$font-family-secondary: 'Sarabun', sans-serif;

$shadow: 0px 0px 13px 0px rgba(236, 236, 241, 0.44);

$base-font: 14px;

// Width variables
$width: 100%;

// Height variables
$height: 100%;

$leftbar-menu-color: #8699ad;
$leftbar-menu-hover-color: #b4c9de;
$leftbar-menu-active-color: #b4c9de;

$modal-lg: 900px;
