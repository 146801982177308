.timesheet-bundle {
  > .card-header {
    background-color: #333547;
    color: #ffffff;

    h6 {
      margin: 0;
      flex: 1;
    }
  }
  transition: box-shadow 350ms ease-in-out;
  background-color: #333547;
  
  &.card {
    border: 1px solid rgba(0, 0, 0, 0.125);
    h6 {
      margin: 0;
      flex: 1;
    }
  }

  .table-total {
    margin-top: 10px;
    margin-bottom: 0;
    
    th {
      border-top: none;
    }
  }
}
