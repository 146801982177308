/* ==============
  Account Pages
===================*/

.home-btn {
  position: absolute;
  top: 15px;
  right: 25px;
  z-index: 9;
}

.wrapper-page {
  margin: 5.5% auto;
  max-width: 492px;
  position: relative;

  .logo-admin {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -40px;
    margin: 0px auto;
    width: 74px;
    height: 74px;
    line-height: 74px;
    border-radius: 50%;
    //box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  }
}

.account-card {
  border-radius: 50px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.25);
  .account-card-content {
    padding: 40px;
  }
}

.user-thumb {
  position: relative;
  z-index: 999;
  img {
    height: 88px;
    margin: 0 auto;
    width: 88px;
  }
}

.ex-page-content {
  h1 {
    font-size: 98px;
    font-weight: 500;
    line-height: 150px;
  }
}

.accountbg {
  position: absolute;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  top: 0;
}

.account-page-full {
  left: 0;
  position: absolute;
  height: 100%;
  margin: 0;
  width: 420px;
  background-color: $white;
  .logo {
    line-height: 70px;
  }
  .card {
    box-shadow: none;
  }
}

.version-text {
  text-align: center;
}

.madeby-text {
  color: #2c106d; 
  font-size: 12px; 
  text-align: center; 
  text-decoration: none;
  padding-bottom: 15px;

  a {
    color: #2c106d; 
  }
}


