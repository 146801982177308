/* ==============
  Helper Classes
===================*/

.p-0 {
  padding: 0;
}
.p-t-10 {
  padding-top: 10px;
}
.p-b-10 {
  padding-bottom: 10px;
}
.p-l-10 {
  padding-left: 10px;
}
.m-0 {
  margin: 0;
}
.m-r-5 {
  margin-right: 5px;
}
.m-r-10 {
  margin-right: 10px;
}
.m-r-15 {
  margin-right: 15px;
}
.m-l-10 {
  margin-left: 10px;
}
.m-l-15 {
  margin-left: 15px;
}
.m-t-5 {
  margin-top: 5px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-t-20 {
  margin-top: 20px;
}
.m-t-30 {
  margin-top: 30px;
}
.m-t-40 {
  margin-top: 40px;
}
.m-b-5 {
  margin-bottom: 5px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-b-30 {
  margin-bottom: 30px;
}
.w-30{
  max-width: 30px;
}
.w-xs {
  min-width: 80px;
}
.w-sm {
  min-width: 95px;
}
.w-md {
  min-width: 110px;
}
.w-lg {
  min-width: 140px;
}

.l-h-23{
  line-height: 23px;
}
.l-h-34 {
  line-height: 34px;
}

.font-10 {
  font-size: 10px;
}

.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.font-24{
  font-size: 24px;
}
.font-30{
  font-size: 30px;
}

.thumb-sm {
  height: 32px;
  width: 32px;
}
.thumb-md {
  height: 48px;
  width: 48px;
}
.thumb-lg {
  height: 88px;
  width: 88px;
}

.font-500{
  font-weight: 500;
}

.font-600{
  font-weight: 600;
}


.header-title {
  font-size: 16px;
}

.client-background {
  text-decoration-color: white;
  color: white;
  background-color: #6c5ce7;
  border-radius: 1%;
  margin: 3px;
  width: 100%;
}

.client-background td {
  padding-left: 5px;
}

.client-background tr th {
  padding: 3px;
  margin: 2px;
  height: 5px;
  float: right;
}

.timesheet-blocked td {
  color: darkgrey;
  background-color: #ecf0f1;
}

.timesheet-notes td {
  color: #e74c3c;
  background-color:#f1c40f;
}