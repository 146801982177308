@import '../../../components/TopBarOverlay/variables';

$title-height: 30px;
$content-wrapper-height: $top-bar-overlay-height - ($top-bar-overlay-vertical-padding * 2) -
  $title-height;

.timesheets-bundle-overlay {
  display: flex;

  .left {
    flex: 1;
    padding-right: 30px;

    @media screen and (max-width: 576px) {
      padding-right: 0;
    }

    h6 {
      margin: 0;
      height: $title-height;
    }

    .list {
      height: $content-wrapper-height;
      overflow: scroll;
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;

      .list-item {
        display: flex;
        padding: 3px 4px 3px 8px;
        margin: 0 10px 5px 0;
        background: #333547;
        border-radius: 5px;
        align-items: center;
      }

      .list-item-icon {
        font-size: 20px;
        margin-right: 8px;
      }

      .list-item-title {
        flex: 1;
        margin-right: 5px;

        @media screen and (max-width: 576px) {
          line-height: normal;
        }

        .amount {
          line-height: normal;
          font-size: 11px;
        }
      }

      .list-item-btn {
        padding: 0;

        @media screen and (max-width: 576px) {
          line-height: 1;
        }
      }
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
