.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y {
  width: auto !important;
}
.forminput label {
  width: 100%;
}

.forminput .input-group {
  width: 100%;
}

.forminput .react-datepicker-wrapper {
  display: grid;
  width: 100%;
}

/* file-upload */
.dropzone {
  position: relative;
  text-align: center;
}

.dz-message {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.dropzone .dz-message:focus {
  outline: 1px solid transparent !important;
}

.badgtab {
  width: 25% !important;
  text-align: left !important;
  border-radius: 5px !important;
}

/* stachbarchart -> chartist */
.stackbarchart .ct-bar {
  stroke-width: 30px;
}

.piechart .ct-chart .ct-label {
  color: white;
  fill: white;
  font-size: 16px;
}

.dataTables_filter {
  float: right;
}

.dataTables_paginate .pagination {
  float: right;
}

.toolbarClassName {
  background: #edeff1;
  border-bottom: 1px solid #e9ecef;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rdw-option-wrapper {
  background-color: transparent;
}

.rdw-editor-main {
  padding: 10px;
}

/* Dropdown */
.splitbtn .dropdown-toggle {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.splitbtn .dropdown-toggle::after {
  margin-left: 0 !important;
}

.dropsplitbtn .dropdown-toggle {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.dropsplitbtn .dropdown-toggle::after {
  margin-left: 0 !important;
}

.smsplitbtn .dropdown-toggle {
  padding-right: 0.372rem;
  padding-left: 0.372rem;
}
.smsplitbtn .dropdown-toggle::after {
  margin-left: 0 !important;
}

.ltsplitbtn .dropdown-toggle {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.ltsplitbtn .dropdown-toggle::before {
  margin-right: 0 !important;
}

@media screen and (max-width: 426px) and (min-width: 320px) {
  .sweet-alert {
    width: 95% !important;
    margin: 0;
    right: 0;
    left: 8px !important;
  }
}

.sweet-alert {
  margin-top: -161px !important;
  /* width: 32em !important; */
}

.sweet-alert h2 {
  font-size: 24px !important;
}

/* form-editors */
.wrapperClassName {
  border: 1px solid #e9ecef;
  border-radius: 0.25rem;
}

.editorClassName,
.ql-editor {
  height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
}

.ql-toolbar.ql-snow {
  background: #edeff1;
}

.rdw-editor-toolbar {
  margin-bottom: 0;
}

.DraftEditor-root {
  height: auto;
}

/* file Xeditable */
.editable-container a {
  border-bottom: dashed 1px #0088cc;
}

/* form-mask */
/* .form-mask .form-control{
  height: calc(1.4em + 0.75rem + 1px);
} */

/* extra-pages (coming soon) */
.coming-box {
  border: none !important;
  box-shadow: 0px 0px 13px 0px rgba(236, 236, 241, 0.44) !important;
  margin-bottom: 30px !important;
  margin: 0 15px;
  padding: 16px 16px 0 16px;
  background-color: white;
}

.coming-watch div {
  font-size: 40px !important;
  color: #626ed4 !important;
  font-weight: 500;
}

.coming-watch div span:first-child {
  height: 50px !important;
}

.coming-watch div span:last-child {
  font-size: 12px !important;
  color: #5b626b;
  text-transform: uppercase;
}

/* email-template */
.email-template .content {
  max-width: 570px !important;
}

/* create btn */
.btn-light {
  color: #212529 !important;
}

/* dashboard */
.dash-chart .chat-avatar {
  margin: 0 8px;
}

/* rating */
.rating-container {
  min-width: auto !important;
}

/* switch */
@media screen and (max-width: 426px) and (min-width: 320px) {
  .react-switch-bg {
    margin-bottom: 8px !important;
  }
}

/* Form wizard */
@media screen and (max-width: 426px) and (min-width: 320px) {
  .form-wizard .badgtab {
    width: 100% !important;
  }
  .form-wizard .col-form-label {
    text-align: left;
  }
}

/* 2-sept */
/* @media screen and (max-width: 426px) and (min-width: 320px) {
  svg {
    width: 100% !important;
  }
  .apexcharts-canvas {
    width: 205px !important;
  }
} */

.coming-watch div span:last-child {
  padding-top: 0px;
}

.react-datepicker-popper {
  z-index: 99;
}

@media screen and (max-width: 426px) and (min-width: 320px) {
  .coming-soon-search-form {
    margin-bottom: 24px;
  }
}

.btn.disabled,
.btn:disabled {
  cursor: default;
  opacity: 0.5 !important;
  background-color: #828282 !important;
  border-color: #828282 !important;
}

.sign-in-microsoft-button {
  height: 41px;
  background: #fff;
  border: 1px solid #8c8c8c;
  padding: 0 12px;
  font-size: 15px;
}

.dev-badge {
  position: absolute;
  top: 0;
  height: 70px;
  width: 110px;
  background: rgba(255, 99, 71, 0.8);
  color: white;
  font-weight: bold;
  font-size: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  transform: rotate(-45deg) translate(-14px, -50px);
  letter-spacing: 1px;
}

.dash-avatar {
  height: 68px;
  width: 68px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;

  margin: auto;
}

.circle-avatar {
  background: 'none.jpg';
  background-size: cover;
  border-radius: 50% 50% 50% 50%;
  background-position: center;
  background-repeat: no-repeat;
  width: 68px;
  height: 68px;
}

div.fadeMe {
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
}

.wrapped-alert {
  overflow-wrap: break-word;
}

.greyedOut {
  color: #bbb;
}

.list-group-item {
  word-wrap: break-word;
}

.card-tip {
  color: white;
  font-size: x-small;
}

.label-class {
  display: flex;
  align-items: center;
}

.btn.btn-save-availability.disabled,
.btn.btn-save-availability:disabled {
  background-color: transparent !important;
  border-color: transparent !important;
}

.absolute-loader-container {
  position: absolute;
  top: 0;
  left: 15px;
  right: 15px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 10;
}
.absolute-loader-container.full-width {
  left: 0;
  right: 0;
}

.absolute-loader-container > div {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -1rem;
  margin-top: -1rem;
}

.weekend-row {
  background-color: #eee;
}

.total {
  font-size: large;
  color: #212529;
}