@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import './scss/style.scss';
@import './scss/icons.scss';
@import './components/TopBarOverlay/variables';
@import 'react-big-calendar/lib/sass/styles';

.spacer {
  height: 0;
  transition: height 350ms ease-in-out;

  &.active {
    height: $top-bar-overlay-height;
  }
}

// Fix overflow issue on modals on iPad (iOS)
body.modal-open > div:last-of-type {
  position: static !important;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.sticky-title {
  position: sticky;
  top: 70px;
  z-index: 1;
  background: #f8f8fa;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  margin: 30px -30px;
  padding: 10px 30px;
}
