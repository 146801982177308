.projects {
  .photo {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-size: cover;
    background-color: #ececec;
    width: 36px;
    height: 36px;
    margin: 2px 3px;
    font-size: 10px;
  }
}
