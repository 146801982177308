@import '../scss/variables';

.timer-badge {
  position: absolute;
  left: 0;
  top: 16px;
  align-items: center;
  padding-left: 15px;
  user-select: none;
  font-size: 16px !important;
  display: flex !important;
  align-items: center;
  padding: 8px 10px !important;
  overflow: hidden;

  &--progress {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;

    &__success {
      background-color: rgba($color: $success, $alpha: 0.2);
    }
    &__warning {
      background-color: rgba($color: $warning, $alpha: 0.2);
    }
    &__danger {
      background-color: rgba($color: $danger, $alpha: 0.2);
    }

    &.active::after {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      animation-duration: 2.5s;
      animation-iteration-count: infinite;
    }

    &__success::after {
      background-color: rgba($color: $success, $alpha: 0.6);
      animation-name: badge-shimmer-success;
    }
    &__warning::after {
      background-color: rgba($color: $warning, $alpha: 0.6);
      animation-name: badge-shimmer-warning;
    }
    &__danger::after {
      background-color: rgba($color: $danger, $alpha: 0.6);
      animation-name: badge-shimmer-danger;
    }
  }

  &--spinner-container {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
  }

  &--spinner {
    margin: 0 !important;
    width: 16px !important;
    height: 16px !important;
    border-width: 2px !important;
  }

  &--time,
  &--project {
    z-index: 1;
  }

  &--task {
    align-items: center;
    font-weight: lighter;
    font-size: 14px;
    opacity: 0;
    width: 0;
    transition: width 500ms 650ms cubic-bezier(0.22, 0.61, 0.36, 1), opacity 300ms 500ms;
    z-index: 1;

    &__text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      text-align: left;
      padding: 3px 0;
    }
  }

  @media screen and (min-width: 640px) {
    &:hover {
      .timer-badge--task {
        width: 200px;
        opacity: 1;
        transition: width 400ms cubic-bezier(0.46, 0.03, 0.52, 0.96), opacity 300ms 150ms;
      }
    }
  }

  &--btn {
    width: 22px;
    height: 22px;
    margin-right: 5px;
    font-size: 16px;
    border: none;
    background: none;
    z-index: 1;
  }

  @media screen and (max-width: 370px) {
    display: none !important;
  }
}

@keyframes badge-shimmer-success {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
    background-color: rgba($color: $success, $alpha: 0.1);
  }
}
@keyframes badge-shimmer-warning {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
    background-color: rgba($color: $warning, $alpha: 0.1);
  }
}
@keyframes badge-shimmer-danger {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
    background-color: rgba($color: $danger, $alpha: 0.1);
  }
}

.seconds-blink {
  animation: blink-animation 1s infinite;
  -webkit-animation: blink-animation 1s infinite;
}

@keyframes blink-animation {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.3;
  }
}
@-webkit-keyframes blink-animation {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.3;
  }
}
